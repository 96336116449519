import { StyledBurger } from "@/components/Burger/index.style";
import { bool, func } from "prop-types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Burger = ({ open, setOpen, ...props }: any) => {
  const isExpanded = open ? true : false;

  return (
    <StyledBurger
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      open={open}
      onClick={() => setOpen(!open)}
      {...props}
    >
      <span />
      <span />
      <span />
    </StyledBurger>
  );
};

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
  isHeaderWhite: bool.isRequired,
  isHeaderBlue: bool,
};

export default Burger;
