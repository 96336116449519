import { StyledBurgerType } from "@/components/Burger/index.type";
import colors from "@/styles/colors";
import styled from "styled-components";

export const StyledBurger = styled.button<StyledBurgerType>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 20px;
  height: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  span {
    width: 20px;
    height: 2px;
    background: ${({ isHeaderBlue, isHeaderWhite }) =>
      isHeaderBlue
        ? "#909090"
        : isHeaderWhite
        ? colors.white100
        : colors.black100};
    border-radius: 10px;
    // transition: all 0.3s linear;
    position: relative;
    transform-origin: 2.5px;
    :first-child {
      width: ${({ open }) => (open ? "25.2px" : "20px")};
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }
    :nth-child(3) {
      width: ${({ open }) => (open ? "25.2px" : "20px")};
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;
