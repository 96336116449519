const MixpanelEvents = {
  // ...
  DRBrowsing: {
    PageDRList: "[Web/Page] DR List (Search)",
    ClickMorePriceInfo: "[Web/Click] More Price Info",
    ClickRecommendedDoctorCard: "[Web/Click] Recommended Doctor Card",
    ClickRecommendedDoctorList: "[Web/Click] Recommended Doctor List",
    ClickRecommendedKeyword: "[Web/Click] Recommended Keyword",
  },
  ContactSales: {
    ClickContentSales: "[Web/Click] Content Sales",
  },
  HealthLab: {
    PageHealthFAQList: "[Web/Page] Health FAQ List",
    PageHealthFAQDetail: "[Web/Page] Health FAQ detail",
    PageHealthWikiList: "[Web/Page] Health Wiki List",
    PageHealthWikiDetail: "[Web/Page] Health Wiki detail",
    ClickShareContent: "[Web/Click] Share Content",
  },
  PageView: {
    Home: "[Web/Page] Home",
    HealthLab: "[Web/Page] Health Lab",
    HealthInfoDetail: "[Web/Page] Health Info Detail",
    HealthInfoList: "[Web/Page] Health Info List",
    HealthTestIntroduction: "[Web/Page] Health Test Introduction",
    HealthQuizIntroduction: "[Web/Page] HealthQuiz Introduction",
    DoctorList: "[Web/Page] DR List (Symptom)",
    DoctorDetail: "[Web/Page] DR Detail",
  },
  ClickEvent: {
    CompanyInfo: "[Web/Click] Company Info",
    Notice: "[Web/Click] Notice",
    MoreDoctorInfo: "[Web/Click] More DR Info",
    DoctorFilter: "[Web/Click] DR Filter",
    ReviewFilter: "[Web/Click] Review Filter",
    ReviewFilterSymptom: "[Web/Click] Review Filter (Symptom)",
    HomeDoctorPointCard: "[Web/Click] DR Point Card at Home",
    HomeDoctorReview: "[Web/Click] DR Review at Home",
    HomePartnershipInquiry: "[Web/Click] Partnership Inquiry",
  },
  SheetEvent: {
    DoctorSortingFilter: "[Web/Sheet] DR Sorting Filter",
  },
  TemporaryValues: {
    ListClickedIndex: "MixPanel_Tmp_ListClickedIndex",
    ListClickedTitle: "MixPanel_Tmp_ListClickedTitle",
  },
  CrackerTest: {
    StartCrackerTest: "[Web/Page] Start Cracker Test",
    CrackerTestOnBoarding: "[Web/Page] Cracker Test On-Boarding",
    TimeCrackerTest: "[Web/Page] Time Cracker Test",
    CrackerTestResult: "[Web/Page] Cracker Test Result",
    HealthCareAtCrackerTestResult:
      "[Web/Click] Healthcare at Cracker Test Result",
    ShareAtCrackerTestResult: "[Web/Click] Share at Cracker Test Result",
    AboutCrackerTest: "[Web/Click] About Cracker Test",
    TestOneMoreTime: "[Web/Click] Test One more Time",
  },
  O2O: {
    pageO2OHub: "[Web/Page] O2O Hub",
    pageO2OHospitalList: "[Web/Page] O2O Hospital List",
    sheetO2ODownloadNudge: "[Web/Sheet] O2O Download Nudge",
    sheetO2ORegionFilter: "[Web/Sheet] O2O Region Filter",
    sheetO2OTreatmentTypeFilter: "[Web/Sheet] O2O Treatment Type Filter",
    sheetO2OExpenseUnitFilter: "[Web/Sheet] O2O Expense Unit Filter",
    sheetO2OExpenseTypeFilter: "[Web/Sheet] O2O Expense Type Filter",
    SheetO2OHospitalSorting: "[Web/Sheet] O2O Hospital Sorting",
    sheetO2ODownloadNudgeHospitalDetail:
      "[Web/Sheet] O2O Download Nudge (Hospital Detail)",
    pageO2OHospitalDetail: "[Web/Page] O2O Hospital Detail",
    clickReviewPagination: "[Web/Click] Review Pagination",
    clickHospitalDetailPagination: "[Web/Click] Hospital Detail Pagination",
    clickTabOnO2OHospitalDetail: "[Web/Click] Tab on O2O Hospital Detail",
    clickNearByHospitalCardHospitalDetail:
      "[Web/Click] Near By Hospital Card (Hospital Detail)",
    clickMoreNearbyHospitalListHospitalDetail:
      "[Web/Click] More Nearby Hospital List (Hospital Detail)",
    clickMoreNearbyHospitalCardHospitalDetail:
      "[Web/Click] More Nearby Hospital Card (Hospital Detail)",
    clickPriceAccordionHospitalDetail:
      "[Web/Click] Price Accordion (Hospital Detail)",
    clickCheapestHospitalAccordionHospitalDetail:
      "[Web/Click] Cheapest Hospital Accordion (Hospital Detail)",
    clickCheapestHospitalLinkHospitalDetail:
      "[Web/Click] Cheapest Hospital Link (Hospital Detail)",
    clickO2ODownloadNudgeCTAHospitalDetail:
      "[Web/Click] O2O Download Nudge CTA (Hospital Detail)",
    sheetDeptDownloadNudge: "[Sheet] Dept Download Nudge",
    clickDeptDownloadNudgeCTA: "[Click] O2O Download Nudge CTA",
  },
  healthQuotes: {
    pageSharedHealthQuotesOnWeb: "[Web/Page] Shared Health Quotes on Web",
    clickGetHealthQuotesOnWEb: "[Web/Click] Get Health Quotes on Web",
  },
  healthBazi: {
    healthBaziHome: "[Web/Page] HealthBazi Home",
    selectTopicOnHealthBazi: "[Web/Page] Select Topic on HealthBazi",
    selectTypeOnHealthBazi: "[Web/Page] Select Type on HealthBazi",
    addInfoOnHealthBazi: "[Web/Page] Add Info on HealthBazi",
    additionalQuestionOnHealthBazi:
      "[Web/Page] Additional Question on HealthBazi",
    HealthBaziLoading: "[Web/Page] HealthBazi Loading",
  },
};

export default MixpanelEvents;
