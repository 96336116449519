import colors from "@/styles/colors";
import styled from "styled-components";

type HeaderContainerType = {
  isHeaderWhite?: boolean;
  isInAppBrowser?: boolean;
  isMyDoctorApp?: boolean;
  isInfinite?: boolean;
};

const Container = styled.div``;

const ContentContainer = styled.div``;

const HeaderContainer = styled.div<HeaderContainerType>`
  top: 0;
  z-index: 100;
  max-width: 560px;
  position: fixed;
  display: flex;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white100};
`;

const CustomHeaderContainer = styled.div<
  HeaderContainerType & { isHeaderFixed?: boolean }
>`
  z-index: 100;
  margin: 0 auto;
  position: ${(props) => (props.isInfinite ? "sticky" : "fixed")};
  top: 0;
  margin-bottom: -60px;
  left: 0;
  right: 0;
  width: 100%;
  display: ${(props) => (props.isMyDoctorApp ? "none" : "flex")};
  max-width: 560px;
  height: 60px;
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white100};
`;

type FooterContainerProp = {
  isMyDoctorApp?: boolean;
  backgroundColor?: string;
};

const FooterContainer = styled.div<FooterContainerProp>`
  width: 100%;
  max-width: 560px;
  /* height: 480px; */
  display: ${(props) => (props.isMyDoctorApp ? "none" : "flex")};
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor || "#2f2f2f"};
`;

const Content = styled.div<{
  isMyDoctorApp?: boolean;
  extraMarginTop?: string;
  isBackgroundBlack?: boolean;
}>`
  position: ${(props) => (props.isBackgroundBlack ? "relative" : "")};
  max-width: 560px;
  margin: 0 auto;
  left: 0;
  right: 0;
  margin-top: ${({ extraMarginTop }) => extraMarginTop};
  padding-top: ${(props) => (props.isMyDoctorApp ? "" : "60px")};
`;

const FloatingCTAContainer = styled.div`
  z-index: 100;
  max-width: 560px;
  position: fixed;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 12px 20px 28px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background-color: #ffffff;
  box-sizing: border-box;
  border-top: 1px solid #f1f2f4;
  color: black;
`;

export {
  Container,
  Content,
  ContentContainer,
  CustomHeaderContainer,
  FloatingCTAContainer,
  FooterContainer,
  HeaderContainer,
};
