import { Button, Font } from "@/design-system";
import { QRCodeSVG } from "qrcode.react";
import { DEFAULT_PADDING } from "src/design-system/constants";
import styled from "styled-components";

const DesktopDownloadBottomSheet = ({ quickLink }: { quickLink: string }) => {
  return (
    <Container>
      <TextContainer>
        <Font type="semibold_24" style={{ textAlign: "center" }}>
          {`QR코드를 찍어\n앱을 다운로드 받으세요`}
        </Font>
        <Font type="regular_16" color="accent">
          {`신규 가입자 모두 3천원을 드려요!`}
        </Font>
      </TextContainer>

      <QRCodeSVG value={quickLink} size={120} />

      <Button.Large
        text="QR 대신 앱스토어 링크로 이동하기"
        level="primary"
        onPress={() => {
          window.open(quickLink);
        }}
        style={{ marginTop: "28px" }}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: ${DEFAULT_PADDING}px 0;
`;

export default DesktopDownloadBottomSheet;
