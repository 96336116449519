import { default as Mixpanel } from "@/constants/mixpanel";
import { ShareContentProps } from "@/hooks/useMixpanel/index.type";
import mixpanel from "mixpanel-browser";

const useMixpanel = () => {
  const track = (event: string, properties?: any) => {
    mixpanel.track(event, properties);
  };

  const identify = (id: string) => {
    mixpanel.identify(id);
  };

  const alias = (id: string) => {
    mixpanel.alias(id);
  };

  const shareContent = (props: ShareContentProps) => {
    track(Mixpanel.HealthLab.ClickShareContent, props);
  };
  const distinctId = mixpanel.get_distinct_id();

  return {
    track,

    identify,
    alias,
    shareContent,
    distinctId,
  };
};

export default useMixpanel;
