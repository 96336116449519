import { StyledMenuType } from "@/components/Menu/index.type";
import colors from "@/styles/colors";
import { motion } from "framer-motion";
import styled from "styled-components";

const StyledMenu = styled(motion.nav)<StyledMenuType>`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white100};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 60px;
  padding-bottom: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.white80};
  z-index: 100;
  > a,
  > div {
    cursor: pointer;
    z-index: 100;
    font-size: 16px;
    height: 48px;
    padding: 0px 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: ${colors.black100};
    text-decoration: none;
    transition: color 0.3s linear;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.5rem;
      text-align: center;
    }
    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }
`;

const EventContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TextArea = styled.div<{ active: boolean; isHeaderWhite: string }>`
  margin-right: 8px;
  border-bottom: ${(props) =>
    props.active
      ? props.isHeaderWhite == "true"
        ? `3px solid ${colors.white100}`
        : `3px solid ${colors.black100}`
      : `3px solid transparent`};
`;

const IconArea = styled.img<{ isTabOpen: boolean }>`
  width: 14px;
  height: 14px;
  transform: ${({ isTabOpen }) => (isTabOpen ? `rotate(180deg)` : `initial`)};
`;

const Accordion = styled.div`
  padding: 0 !important;
`;

const EventLinkItem = styled.div<{ isHeaderWhite: string }>`
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) =>
    props.isHeaderWhite == "true" ? colors.white100 : colors.black100};
  margin: 16px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    font-weight: 600;
  }
`;

export {
  Accordion,
  EventContainer,
  EventLinkItem,
  IconArea,
  StyledMenu,
  TextArea,
};
