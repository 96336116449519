import { StyledMenu } from "@/components/Menu/index.style";
import MixpanelEvents from "@/constants/mixpanel";
import useMixpanel from "@/hooks/useMixpanel";
import { AnimatePresence } from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/router";
import { bool, func, string } from "prop-types";
import { useState } from "react";

const MENU_ELEMENT = [
  { url: "/telemedicine", displayName: "비대면 진료" },
  { url: "/healthLab", displayName: "건강 연구소" },
  { url: "/event", displayName: "이벤트" },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Menu = ({ open, setOpen, ...props }: any) => {
  const isHeaderWhite = props.isHeaderWhite ?? false;
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;
  const [isEventTabOpen, setIsEventTabOpen] = useState(false);
  const mixpanel = useMixpanel();

  const router = useRouter();
  const handlePartnerClick = (event) => {
    event.preventDefault();
    router.push("/#partner-section");
  };

  return (
    <AnimatePresence>
      {open && (
        <StyledMenu
          transition={{ duration: 0.2 }}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          open={props.open}
          // aria-hidden={!isHidden}
          isHeaderWhite={isHeaderWhite?.toString()} // lowercase when using framer div
          // {...props}
        >
          {MENU_ELEMENT.map((element) => (
            <Link href={element.url} key={element.displayName}>
              <a tabIndex={tabIndex} onClick={() => setOpen(false)}>
                {element.displayName}
              </a>
            </Link>
          ))}
          <a
            tabIndex={tabIndex}
            onClick={() => {
              mixpanel.track(MixpanelEvents.ClickEvent.Notice);
              window.open("https://mydoctor.oopy.io/", "_blank");
            }}
          >
            공지사항
          </a>
          <a
            tabIndex={tabIndex}
            onClick={(event) => {
              setOpen(false);
              handlePartnerClick(event);
            }}
          >
            제휴 신청
          </a>
          <a
            tabIndex={tabIndex}
            onClick={() => {
              mixpanel.track(MixpanelEvents.ClickEvent.CompanyInfo);
              window.open("https://merakiplace.oopy.io/", "_blank");
            }}
          >
            회사 소개
          </a>
        </StyledMenu>
      )}
    </AnimatePresence>
  );
};

Menu.propTypes = {
  open: bool.isRequired,
  isHeaderWhite: bool.isRequired,
  setOpen: func.isRequired,
  id: string.isRequired,
};

export default Menu;
