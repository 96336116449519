import * as S from "@/components/HeaderArea/index.style";
import { BottomSheet } from "@/global-components/components";
import useGetPlatform from "@/hooks/useGetPlatform";
import useMixpanel from "@/hooks/useMixpanel";
import DesktopDownloadBottomSheet from "@/pages/home/DesktopDownloadBottomSheet";
import { AnimatePresence } from "framer-motion";
import Link from "next/link";
import React, { useState } from "react";
import FocusLock from "react-focus-lock";
import Burger from "../Burger";
import Menu from "../Menu";

type HeaderAreaProps = {
  quickLink?: string;
  isHomeQuickLink?: boolean;
  isWegovy?: boolean;
};

const HeaderArea = ({
  isHomeQuickLink,
  isWegovy = false,
  quickLink = isHomeQuickLink
    ? "https://my-doctor.app.link/sNIjxvg7uHb"
    : "https://my-doctor.app.link/b1TLxKV0uHb",
}: HeaderAreaProps) => {
  const { track } = useMixpanel();
  const [open, setOpen] = useState(false);
  const menuId = "main-menu";

  const { platform } = useGetPlatform();

  return (
    <S.Header>
      <AnimatePresence>
        <S.LogoWrap
          initial={{ opacity: 1 }}
          transition={{ duration: 0.1 }}
          exit={{ opacity: 0 }}
        >
          <Link href={"/"} passHref>
            <a style={{ textDecoration: "none" }}>
              <S.BlueLogo
                src="https://d7qkbi83dtokl.cloudfront.net/prod/web/landingPage/image/logo/logo_L_Blue.png"
                alt="나만의닥터"
              />
            </a>
          </Link>
        </S.LogoWrap>
      </AnimatePresence>
      <S.HeaderRightArea>
        <S.CTAButton
          isWegovy={isWegovy}
          onClick={() => {
            track("[Click] Download CTA on GNB");
            if (platform === "DESKTOP") {
              return BottomSheet.show({
                children: <DesktopDownloadBottomSheet quickLink={quickLink} />,
              });
            }
            return window.open(quickLink);
          }}
        >
          앱 다운로드
        </S.CTAButton>
        <div>
          <FocusLock disabled={!open}>
            <Burger
              open={open}
              setOpen={setOpen}
              aria-controls={menuId}
              isHeaderWhite={false}
            />
            <Menu
              open={open}
              setOpen={setOpen}
              id={menuId}
              isHeaderWhite={false}
            />
          </FocusLock>
        </div>
      </S.HeaderRightArea>
    </S.Header>
  );
};

export default React.memo(HeaderArea);
