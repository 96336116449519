import { useEffect, useState } from "react";

const MAX_MOBILE_WIDTH = 560;

type Platform = "IOS" | "ANDROID" | "DESKTOP";

const IOS_USER_AGENT_REGEX = /iPad|iPhone|iPod|Apple/;

const ANDROID_USER_AGENT_REGEX = /Android/;

const DESKTOP_USER_AGENT_REGEX = /Macintosh|Windows NT/;

const useGetPlatform = () => {
  const [platform, setPlatform] = useState<Platform | null>(null);

  useEffect(() => {
    if (DESKTOP_USER_AGENT_REGEX.test(navigator.userAgent)) {
      return setPlatform("DESKTOP");
    }
    if (ANDROID_USER_AGENT_REGEX.test(navigator.userAgent)) {
      return setPlatform("ANDROID");
    }
    if (IOS_USER_AGENT_REGEX.test(navigator.userAgent)) {
      return setPlatform("IOS");
    }

    return setPlatform("DESKTOP");
  }, []);

  return {
    platform,
  };
};

export default useGetPlatform;
